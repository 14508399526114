<template>
  <v-dialog v-model="dialog" width="800px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="selectedFormTemplates.length"
        :value="selectedFormTemplates.length"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px
        }`"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected Form Templates
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Mass Edit {{ isMobile ? '' : 'Form Templates' }} </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row align="center">
          <v-col cols="12" class="mb-n6">
            <v-row align="center">
              <v-col class="shrink pr-0">
                <v-icon>mdi-folder-outline</v-icon>
              </v-col>
              <v-col class="grow">
                <CustomizedAutoComplete
                  :value="selectedGroupId"
                  hide-details
                  :items="groups"
                  item-value="groupId"
                  item-text="groupName"
                  clearable
                  :label="`Select a group to move ${
                    isMobile ? 'to' : 'the form templates to'
                  }`"
                  @change="selectedGroupId = $event"
                />
              </v-col>
            </v-row>
            <v-row v-if="isInternalAdmin" align="center">
              <v-col class="shrink pr-0">
                <v-icon>mdi-tag-multiple-outline</v-icon>
              </v-col>
              <v-col class="grow">
                <CustomizedAutoComplete
                  @change="iaSelectedLabels = $event"
                  :value="iaSelectedLabels"
                  :items="iaLabels"
                  multiple
                  clearable
                  hide-details
                  item-value="id"
                  deletable-chips
                  item-text="name"
                  label="Select label(s)"
                />
              </v-col>
            </v-row>
            <v-row class="mb-4" align="center">
              <v-col class="shrink pr-0">
                <v-icon>mdi-file-eye-outline</v-icon>
              </v-col>
              <v-col class="pl-2 grow">
                <PublishButtonGroup
                  :is-published="massPublishedState"
                  @click="updateMassPublish($event)"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="!isMobile">
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="lightBg">
                    <tr>
                      <th class="text-left">Selected Form Templates</th>
                      <th class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(formTemplate, index) in payload" :key="index">
                      <td>
                        {{ formTemplate.name }}
                      </td>
                      <td align="right" :style="{ width: '200px' }">
                        <v-row no-gutters align="center">
                          <v-spacer></v-spacer>
                          <v-col>
                            <PublishButtonGroup
                              :is-published="formTemplate.isPublished"
                              @click="formTemplate.isPublished = $event"
                            />
                          </v-col>

                          <v-col class="shrink ml-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  small
                                  v-on="on"
                                  icon
                                  color="red"
                                  @click="unselect(formTemplate, index)"
                                >
                                  <v-icon> mdi-minus-circle-outline </v-icon>
                                </v-btn>
                              </template>
                              <span>Remove from list</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col v-if="isMobile" cols="12">
            <v-card
              v-for="(formTemplate, index) in payload"
              :key="index"
              class="mb-4"
            >
              <v-card-title class="text-body-1 msaGrey">
                {{ formTemplate.name }}
              </v-card-title>
              <v-card-text class="pt-4">
                <v-row no-gutters align="center">
                  <v-col class="shrink">
                    <v-switch
                      class="mt-0"
                      v-model="formTemplate.isPublished"
                      color="primary"
                      dense
                      hide-details
                      :true-value="1"
                      :false-value="0"
                    ></v-switch>
                  </v-col>
                  <v-col cols="3">
                    {{ formTemplate.isPublished ? 'Publish' : 'Unpublish' }}
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="shrink ml-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          v-on="on"
                          icon
                          color="red"
                          @click="unselect(formTemplate)"
                        >
                          <v-icon> mdi-minus-circle-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>Remove from list</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red white--text" @click="deleteAll()">
          Delete all {{ isMobile ? '' : 'form templates' }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text dark color="red" @click="cancel()">Cancel</v-btn>
        <v-btn class="msaBlue white--text" @click="confirm()"> confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PublishButtonGroup from '@/components/PublishButtonGroup';
export default {
  name: 'FormTemplateSelectionModal',
  components: { PublishButtonGroup },
  props: {
    selectedFormTemplates: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      selectedGroupId: null,
      checkSelectedTrigger: 1,
      groups: [],
      iaSelectedLabels: [],
      iaLabels: [],
      payload: [],
    };
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.prepareData();

          this.getFromTemplateGroups();

          if (this.isInternalAdmin) {
            this.iaGetFormLabels();
          }
        }
      },
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 292 / 2;
    },
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
    isAllPublished() {
      return this.payload.every((form) => form.isPublished === 1);
    },
    isNonePublished() {
      return this.payload.every((form) => form.isPublished === 0);
    },
    massPublishedState() {
      if (this.isAllPublished) {
        return 1;
      }

      if (this.isNonePublished) {
        return 0;
      }

      return null;
    },
  },
  methods: {
    prepareData() {
      this.payload = JSON.parse(JSON.stringify(this.selectedFormTemplates));
      const options = {
        caseFirst: 'upper',
        ignorePunctuation: true,
      };
      this.payload.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, options),
      );
    },
    iaGetFormLabels() {
      const url = 'get-all-attachable-labels?format=json';
      const params = {
        loaderText: 'Loading... ',
      };

      this.$axios
        .post(url, params)
        .then((response) => (this.iaLabels = response.data))
        .catch((error) => error);
    },
    getFromTemplateGroups() {
      const params = {
        loaderText: 'Loading...',
      };

      if (this.isInternalAdmin) {
        params.search = '';
      }

      const url = this.isInternalAdmin
        ? 'ia-get-all-form-templates'
        : 'get-all-form-template-groups';

      this.$axios.post(url, params).then((response) => {
        this.groups = response.data;
      });
    },
    confirm() {
      const url = this.isInternalAdmin
        ? 'public-form-template-mass-management?format=json'
        : 'form-template-mass-management?format=json';
      const params = {
        loaderText: 'Saving...',
        selectedGroupId: this.selectedGroupId ?? 0,
        formTemplates: this.payload,
      };

      if (this.isInternalAdmin) {
        params.selectedLabels = this.iaSelectedLabels;
      }

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('reload');
          this.close();
        })
        .catch((error) => error);
    },
    updateMassPublish(val) {
      this.payload.forEach((ft) => (ft.isPublished = val));
    },
    unselect(formTemplate, index) {
      this.$emit('onUnselect', formTemplate);
      this.checkSelectedTrigger++;
      this.payload.splice(index, 1);
      if (this.selectedFormTemplates.length == 0) {
        this.close();
      }
    },
    close() {
      if (this.checkSelectedTrigger > 1) {
        this.$emit('checkSelected');
      }
      this.dialog = false;
    },
    deleteAll() {
      this.$root.showMessage(
        'Confirm',
        'Are you sure? This is irreversible.',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
      const confirm = () => {
        const params = {
          loaderText: 'Loading...',
          formTemplateIds: this.payload.map((ft) => ft.id),
        };
        const url = this.isInternalAdmin
          ? 'delete-public-form-templates?format=json'
          : 'delete-form-templates?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('reload');
            this.close();
          })
          .catch((error) => error);
      };
    },
    cancel() {
      this.$emit('onCancel');
      this.dialog = false;
    },
  },
  mounted() {
    this.getFromTemplateGroups();
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
