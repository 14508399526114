<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom v-if="group.groupId == 0">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
            data-testid="add-group"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span>Add New Group</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
            :data-testid="`edit-group-${group.groupName}`"
          >
            <v-icon color="msaBlue"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        {{ modalTitle }}
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <v-form ref="form" @submit.prevent :lazy-validation="false">
              <v-text-field
                v-model="edited.groupName"
                outlined
                dense
                label="Group Name"
                :rules="required"
                maxlength="500"
                counter
                data-testid="group-name"
              >
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="close()"> cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          :disabled="disableSave"
          @click="save()"
          data-testid="save-group"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'FormTemplateGroupDetail',
  props: {
    group: {
      type: Object,
      default() {
        return {
          groupId: 0,
          groupName: '',
          groupPosition: 0,
          formTemplates: [],
          isOrdering: false,
        };
      },
    },
  },
  data() {
    return {
      dialog: false,
      edited: {},
      required: [(v) => !!v || 'This field is required'],
    };
  },
  watch: {
    dialog(nv) {
      if (nv) {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      }
    },
  },
  computed: {
    modalTitle() {
      return this.edited.groupId == 0 ? 'Add New Group' : 'Edit Group';
    },
    disableSave() {
      if (this.edited.groupName == this.group.groupName) {
        return true;
      }
      if (this.edited.groupName && this.edited.groupName.trim() == '') {
        return true;
      }
      if (!this.edited.groupName) {
        return true;
      }
      return false;
    },
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
  },
  methods: {
    popData() {
      this.edited = { ...this.group };
    },
    close() {
      //this.$refs.form.clear();
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
    save() {
      const params = {
        loaderText: 'loading...',
        group: this.edited,
      };
      const url =
        this.edited.groupId == 0
          ? this.isInternalAdmin
            ? 'create-public-form-template-group?format=json'
            : 'create-form-template-group?format=json'
          : this.isInternalAdmin
          ? 'edit-public-form-template-group?format=json'
          : 'edit-form-template-group?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          if (this.edited.groupId == 0) {
            this.edited.groupId = response.data;
          }
          this.$emit('saved', this.edited);
          this.close();
        })
        .catch((error) => error);
    },
  },
  mounted() {},
};
</script>
