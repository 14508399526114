<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-data-table
        v-model="thisSelected"
        :disable-sort="isMobile"
        :headers="headers"
        :item-class="rowClass"
        :items="group.formTemplates"
        :show-select="!isOrdering"
        :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
        @item-selected="onSelect($event)"
        @toggle-select-all="onSelect($event)"
        checkbox-color="msaBlue"
        class="form-template-list"
        disable-filtering
        disable-pagination
        hide-default-footer
        item-key="id"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <span v-if="forImport">
                {{ item.name }}
              </span>
              <span
                v-else
                :style="{ cursor: 'pointer' }"
                @click="goToDetail(item)"
                class="msaBlue--text"
              >
                {{ item.name }}
              </span>
            </v-col>
            <v-col v-if="item.isImported" class="text-caption">
              This Public Template was last added to your Private Library on
              {{ item.importedDate | filterAsLocalDate }}
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <span v-if="!isOrdering">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="goToDetail(item)" icon color="msaBlue">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="duplicateFormTemplate(item.id, item.name.length)"
                  color="msaBlue"
                  icon
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              Duplicate
            </v-tooltip>
            <PublishToggle
              :is-published="item.isPublished"
              @click="togglePublish(item)"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="exportFormTemplate(item)"
                  color="msaBlue"
                  icon
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              Export as PDF
            </v-tooltip>
            <v-tooltip v-if="!isSearching" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  v-on="on"
                  icon
                  @click="
                    $emit('isOrdering');
                    item.isOrdering = true;
                  "
                >
                  <v-icon> mdi-swap-vertical </v-icon>
                </v-btn>
              </template>
              Change Position
            </v-tooltip>
            <FormTemplateDeletion
              :formTemplateId="item.id"
              @deleted="$emit('onFormTemplateDeleted', item.id)"
            />
          </span>
          <span v-if="isOrdering && !item.isOrdering"></span>
          <span v-if="item.isOrdering" class="pl-0 pr-1" align="right">
            <ChangeOrderButtonGroup
              :currentIndex="formTemplateIndex(item)"
              :maxIndex="group.formTemplates.length - 1"
              @updatePositions="
                $emit('updateFormTemplatePositions');
                item.isOrdering = false;
              "
              @onOrder="changeFormTemplateOrder($event)"
            />
          </span>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="!isOrdering && !forImport">
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="goToDetail(newFormTemplateDetails)"
            class="msaBlue white--text"
            small
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        Add New Form Template
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';
import FormTemplateDeletion from '@/components/FormTemplates/FormTemplateDeletion.vue';
import PublishToggle from '@/components/PublishToggle';
export default {
  name: 'FormTemplateList',
  props: {
    group: {
      type: Object,
    },
    isOrdering: {
      type: Boolean,
    },
    selected: {
      type: Array,
    },
    checkSelectedTrigger: {
      type: Number,
    },
    isSearching: {
      type: Boolean,
    },
    forImport: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormTemplateDeletion,
    ChangeOrderButtonGroup,
    PublishToggle,
  },
  data() {
    return {
      headers: this.forImport
        ? [
            {
              text: 'Name',
              value: 'name',
              class: 'lightGrey',
            },
          ]
        : [
            {
              text: 'Name',
              value: 'name',
              class: 'lightGrey',
            },
            {
              text: 'Actions',
              value: 'actions',
              align: 'center',
              class: 'lightGrey px-0',
              sortable: false,
              width: '250px',
            },
          ],
      newFormTemplateDetails: {
        id: 0,
        name: '',
        allowAdditionalHazards: 0,
        allowTags: 1,
        description: '',
        isDuplicable: 1,
        isPrivate: 0,
        isPublished: 0,
        managerApprovalRequired: 0,
        safetyRepApprovalRequired: 0,
        supervisorApprovalRequired: 1,
        sections: [],
        watchers: [],
      },
      thisSelected: [],
    };
  },
  computed: {
    formTemplateIndex() {
      return (formTemplate) =>
        this.group.formTemplates.findIndex((ft) => ft.id == formTemplate.id);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
  },
  watch: {
    checkSelectedTrigger() {
      if (!this.forImport) {
        this.checkSelected();
      }
    },
  },
  mounted() {
    if (!this.forImport) {
      this.checkSelected();
    }
  },
  methods: {
    goToDetail(formTemplate) {
      if (formTemplate.id == 0) {
        this.isInternalAdmin
          ? (formTemplate.publicFormTemplateGroupId = this.group.groupId)
          : (formTemplate.formTemplateGroupId = this.group.groupId);
      }
      this.$store.commit('updateSelectedFormTemplate', formTemplate);
      this.$router.push({
        name: this.isInternalAdmin
          ? 'PublicFormTemplateDetail'
          : 'FormTemplateDetail',
        params: {
          id: formTemplate.id,
        },
      });
    },
    togglePublish(formTemplate) {
      const params = {
        loaderText: 'Loading...',
        id: formTemplate.id,
      };
      const url = this.isInternalAdmin
        ? 'toggle-public-form-template-publish?format=json'
        : 'toggle-form-template-publish?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          formTemplate.isPublished = formTemplate.isPublished ? 0 : 1;
        })
        .catch((error) => error);
    },
    duplicateFormTemplate(formTemplateId, nameLength) {
      const maxTemplateName = 493; // 500 MINUS ' (Copy)'.length
      if (nameLength > maxTemplateName) {
        this.$root.showMessage(
          'Warning',
          "Duplicating this template will remove characters form the end of the name to make room for ' (Copy) '. Do you want to proceed?",
          () => this.duplicateFormTemplate(formTemplateId, 0),
          () => {},
          'CONFIRM',
          'CANCEL',
        );

        return;
      }

      const params = {
        loaderText: 'Loading...',
        id: formTemplateId,
      };
      const url = this.isInternalAdmin
        ? 'duplicate-public-form-template?format=json'
        : 'duplicate-form-template?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.$emit('onDuplicate', response.data);
        })
        .catch((error) => error);
    },
    exportFormTemplate(formTemplate) {
      const params = {
        id: formTemplate.id,
        loaderText: 'Loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      const url = this.isInternalAdmin
        ? 'export-public-form-template?format=json'
        : 'export-form-template?format=json';

      this.$axios
        .post(url, params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, formTemplate.name + '.pdf');
        })
        .catch((error) => error);
    },
    checkSelected() {
      this.thisSelected = this.group.formTemplates.filter((formTemplate) =>
        this.selected.some((selected) => selected.id == formTemplate.id),
      );
    },
    onSelect(event) {
      if (event.item != undefined) {
        this.onSelectOne(event);
      }

      // Handle event when user uses SHIFT to select multiple items
      if (event.currentItem != undefined) {
        event.item = event.currentItem;
        this.onSelectOne(event);
      }

      if (event.items != undefined) {
        this.onSelectAll(event);
      }
    },
    onSelectOne(event) {
      this.$emit('onSelect', {
        formTemplates: [event.item],
        value: event.value,
      });
    },
    onSelectAll(event) {
      this.$emit('onSelect', {
        formTemplates: this.group.formTemplates,
        value: event.value,
      });
    },
    changeFormTemplateOrder(event) {
      const movedItem1 = { ...this.group.formTemplates[event.index] };
      const movedItem2 = {
        ...this.group.formTemplates[event.index + event.value],
      };

      this.$set(
        this.group.formTemplates,
        event.index + event.value,
        movedItem1,
      );
      this.$set(this.group.formTemplates, event.index, movedItem2);
    },
    rowClass(item) {
      if (this.forImport) {
        if (item.isImported) {
          return 'msaGreen';
        }
        return '';
      }
    },
  },
};
</script>

<!-- Select all checkbox -->
<style>
.form-template-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
