<template>
  <v-card>
    <v-card-title class="msaGrey"> Groups </v-card-title>
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-simple-table
            :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
          >
            <template v-slot:default>
              <colgroup>
                <col span="1" />
                <col span="1" :style="{ width: '155px' }" />
              </colgroup>
              <tbody>
                <tr v-for="(group, index) in groups" :key="group.groupId">
                  <td class="msaBlue--text">
                    <span
                      @click="$emit('goToGroup', group.groupId)"
                      :style="{ cursor: 'pointer' }"
                    >
                      {{ group.groupName }}
                    </span>
                  </td>
                  <td class="pl-0 pr-1" align="right" v-if="!isOrdering">
                    <FormTemplateGroupDetail
                      :group="group"
                      @saved="$emit('updateGroup', $event)"
                    />
                    <v-tooltip bottom v-if="!isSearching">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-blur
                          text
                          small
                          v-on="on"
                          @click="
                            $emit('isOrdering');
                            group.isOrdering = true;
                          "
                        >
                          <v-icon> mdi-swap-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>Change Position</span>
                    </v-tooltip>
                    <FormTemplateGroupDeletion
                      :groupId="group.groupId"
                      @deleted="$emit('deleteGroup', group.groupId)"
                    />
                  </td>
                  <td v-if="isOrdering && !group.isOrdering"></td>
                  <td v-if="group.isOrdering" class="pl-0 pr-1" align="right">
                    <ChangeOrderButtonGroup
                      :currentIndex="index"
                      :maxIndex="groups.length - 1"
                      @updatePositions="
                        $emit('updateGroupPositions');
                        group.isOrdering = false;
                      "
                      @onOrder="$emit('onOrder', $event)"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-col align="right" v-if="!isOrdering">
            <FormTemplateGroupDetail @saved="$emit('updateGroup', $event)" />
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import FormTemplateGroupDetail from '@/components/FormTemplates/FormTemplateGroupDetail.vue';
import FormTemplateGroupDeletion from '@/components/FormTemplates/FormTemplateGroupDeletion.vue';
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';

export default {
  name: 'FormTemplateGroups',
  props: {
    groups: {
      type: Array,
    },
    isOrdering: {
      type: Boolean,
    },
    isSearching: {
      type: Boolean,
    },
  },
  components: {
    FormTemplateGroupDetail,
    FormTemplateGroupDeletion,
    ChangeOrderButtonGroup,
  },
  methods: {},
};
</script>

<style></style>
