<template>
  <v-container :class="selected.length > 0 ? 'pb-16' : ''" fluid>
    <v-row>
      <v-col cols="12" md="4">
        <SearchBar
          v-if="!isOrdering"
          @search="search = $event"
          searchLabel="Search by form title..."
        />
      </v-col>
      <v-col v-if="!isInternalAdmin" cols="12" align="right" md="8">
        <Import1LifeFormTemplates @imported="getAllFormTemplates()" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <FormTemplateGroups
          :groups="groupedFormTemplates"
          :isOrdering="isOrdering"
          :isSearching="isSearching"
          @updateGroup="updateGroup($event)"
          @deleteGroup="deleteGroup($event)"
          @isOrdering="isOrdering = true"
          @onOrder="changeGroupOrder($event)"
          @updateGroupPositions="updateGroupPositions()"
          @goToGroup="goToGroup($event)"
        />
      </v-col>
      <v-col cols="12" md="8">
        <v-expansion-panels v-model="expanded" multiple>
          <v-expansion-panel
            v-for="group in groupedFormTemplates"
            :key="group.groupId"
          >
            <v-expansion-panel-header
              v-slot="{ open }"
              :ref="'group' + group.groupId"
              class="msaGrey"
              hide-actions
            >
              <v-row no-gutters align="center">
                <v-col class="shrink">
                  <v-icon v-if="open">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col class="grow pl-4 font-weight-medium">
                  {{ group.groupName }}
                  <v-badge
                    :content="`${group.formTemplates.length}`"
                    class="ml-2"
                  ></v-badge>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <FormTemplateList
                :group="group"
                :selected="selected"
                :isOrdering="isOrdering"
                :isSearching="isSearching"
                :checkSelectedTrigger="checkSelectedTrigger"
                @onFormTemplateDeleted="deleteFormTemplate(group, $event)"
                @onDuplicate="insertDuplicatedFormTemplate($event)"
                @onSelect="handleSelection($event)"
                @isOrdering="isOrdering = true"
                @updateFormTemplatePositions="
                  updateFormTemplatePositions(group)
                "
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-if="isSearching && groupedFormTemplates.length == 0">
      <v-col cols="12" align="center">No results found</v-col>
    </v-row>
    <FormTemplateSelectionModal
      v-if="selected.length > 0 && !isOrdering"
      :selectedFormTemplates="selected"
      @onUnselect="handleUnselect($event)"
      @reload="reload()"
      @checkSelected="checkSelectedTrigger++"
      @onCancel="unselectAll()"
    />
  </v-container>
</template>
<script>
import Import1LifeFormTemplates from '@/components/FormTemplates/Import1LifeFormTemplates.vue';
import SearchBar from '@/components/SearchBar.vue';
import FormTemplateGroups from '@/components/FormTemplates/FormTemplateGroups.vue';
import FormTemplateList from '@/components/FormTemplates/FormTemplateList.vue';
import FormTemplateSelectionModal from '@/components/FormTemplates/FormTemplateSelectionModal.vue';
export default {
  name: 'FormTemplates',
  components: {
    FormTemplateGroups,
    FormTemplateList,
    SearchBar,
    FormTemplateSelectionModal,
    Import1LifeFormTemplates,
  },
  data() {
    return {
      groupedFormTemplates: [],
      search: '',
      isOrdering: false,
      expanded: [],
      selected: [],
      checkSelectedTrigger: 1,
    };
  },
  computed: {
    isSearching() {
      return this.search.trim().length > 0;
    },
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
  },
  watch: {
    search() {
      this.getAllFormTemplates();
    },
  },
  methods: {
    getAllFormTemplates() {
      const params = {
        loaderText: 'Loading...',
        search: this.search,
      };

      const url = this.isInternalAdmin
        ? 'ia-get-all-form-templates?format=json'
        : 'get-all-form-templates?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          if (this.isInternalAdmin) {
            this.groupedFormTemplates = response.data;
          } else {
            this.groupedFormTemplates = response.data.groups;
          }

          this.$nextTick(() => {
            this.expandAll();
          });
        })
        .catch((error) => error);
    },
    updateGroup(updatedGroup) {
      const groupIndex = this.groupedFormTemplates.findIndex(
        (g) => g.groupId == updatedGroup.groupId,
      );

      if (groupIndex == -1) {
        this.groupedFormTemplates.push(updatedGroup);
      } else {
        this.$set(this.groupedFormTemplates, groupIndex, updatedGroup);
      }
      this.expandAll();
    },
    expandAll() {
      this.expanded = this.groupedFormTemplates.map((_, i) => i);
    },
    deleteGroup(groupId) {
      const groupIndex = this.groupedFormTemplates.findIndex(
        (g) => g.groupId == groupId,
      );

      this.groupedFormTemplates.splice(groupIndex, 1);

      this.$nextTick(() => {
        this.expandAll();
      });
    },
    changeGroupOrder(event) {
      const movedItem1 = { ...this.groupedFormTemplates[event.index] };

      const movedItem2 = {
        ...this.groupedFormTemplates[event.index + event.value],
      };

      this.$set(this.groupedFormTemplates, event.index, movedItem2);
      this.$set(
        this.groupedFormTemplates,
        event.index + event.value,
        movedItem1,
      );
    },
    updateGroupPositions() {
      const groups = this.groupedFormTemplates.map((group, index) => {
        group.groupPosition = index + 1;
        return { id: group.groupId, position: group.groupPosition };
      });
      const params = {
        loaderText: 'Loading...',
        groups: groups,
      };

      const url = this.isInternalAdmin
        ? 'update-public-form-template-group-positions?format=json'
        : 'update-form-template-group-positions?format=json';

      this.$axios
        .post(url, params)
        .then(() => (this.isOrdering = false))
        .catch((error) => error);
    },
    updateFormTemplatePositions(group) {
      const formTemplates = group.formTemplates.map((ft, index) => {
        ft.position = index + 1;
        return { id: ft.id, position: ft.position };
      });

      const params = {
        loaderText: 'Loading...',
        formTemplates: formTemplates,
        groupId: group.groupId,
      };

      const url = this.isInternalAdmin
        ? 'update-public-form-template-positions?format=json'
        : 'update-form-template-positions?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.isOrdering = false;
          this.checkSelectedTrigger++;
        })
        .catch((error) => error);
    },
    goToGroup(groupId) {
      const options = {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      };
      this.$vuetify.goTo(this.$refs[`group${groupId}`][0], options);
    },
    deleteFormTemplate(group, formTemplateId) {
      let index = group.formTemplates.findIndex((f) => f.id == formTemplateId);
      group.formTemplates.splice(index, 1);

      index = this.selected.findIndex((s) => s.id == formTemplateId);
      if (index != -1) {
        this.selected.splice(index, 1);
      }
    },
    insertDuplicatedFormTemplate(formTemplate) {
      const group = this.groupedFormTemplates.find(
        (g) => g.groupId == formTemplate.groupId,
      );

      if (group != undefined) {
        group.formTemplates.push(formTemplate);
      }
    },
    handleSelection(event) {
      if (event.value) {
        this.selected = this.selected
          .concat(JSON.parse(JSON.stringify(event.formTemplates)))
          // remove duplicates
          .filter(
            (item, index, self) =>
              self.findIndex((t) => t.id == item.id) == index,
          );
      } else {
        this.selected = this.selected.filter(
          (selected) =>
            !event.formTemplates.some(
              (formTemplate) => formTemplate.id == selected.id,
            ),
        );
      }
    },
    handleUnselect(event) {
      this.selected = this.selected.filter(
        (selected) => selected.id != event.id,
      );
      this.checkSelectedTrigger++;
    },
    reload() {
      this.selected = [];
      this.getAllFormTemplates();
      this.checkSelectedTrigger++;
    },
    unselectAll() {
      this.selected = [];
      this.checkSelectedTrigger++;
    },
  },
  mounted() {
    this.getAllFormTemplates();
  },
};
</script>
