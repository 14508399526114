<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Delete Form Template Group
      </v-card-title>
      <v-card-text class="mt-2 text-body-1">
        Are you sure? Deleting a group also deletes all the form templates
        within it. This is irreversible.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" text class="red--text"> Cancel </v-btn>

        <v-btn @click="confirm()" class="msaBlue white--text"> confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'FormTemplateGroupDeletion',
  props: {
    groupId: {
      type: Number,
    },
  },
  computed: {
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    confirm() {
      const params = {
        loaderText: 'deleting...',
        groupId: this.groupId,
      };
      const url = this.isInternalAdmin
        ? 'delete-public-form-template-group?format=json'
        : 'delete-form-template-group?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('deleted', this.groupId);
          this.close();
        })
        .catch(error => error);
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style></style>
