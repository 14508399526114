<template>
  <v-dialog v-model="dialog" width="750" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="get1LifeTemplates()"
          >
            <v-icon> mdi-import </v-icon>
          </v-btn>
        </template>
        <span>Import From Library</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow">
              Import 1Life Form Templates
            </v-col>
            <v-col class="shrink pr-6">
              <v-btn icon @click="close()">
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="mt-4">
        <SearchBar
          class="mb-4"
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by template name..."
        />
        <v-expansion-panels multiple v-model="expanded">
          <v-expansion-panel v-for="group in items" :key="group.groupId">
            <v-expansion-panel-header
              class="msaGrey"
              hide-actions
              v-slot="{ open }"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink">
                  <v-icon v-if="open">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col class="grow pl-4 font-weight-medium">
                  {{ group.groupName }}
                  <v-badge
                    class="ml-2"
                    :content="`${group.formTemplates.length}`"
                  ></v-badge>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <FormTemplateList
                :group="group"
                :selected="selected"
                :forImport="true"
                @onSelect="handleSelection($event)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="isSearching && items.length == 0" class="text-center">
          No results found
        </div>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid class="mr-4">
          <v-row>
            <v-col class="grow" align="right">
              <v-btn class="white red--text" @click="close()"> cancel </v-btn>
            </v-col>
            <v-col class="shrink">
              <v-badge
                :value="selected.length"
                :content="selected.length"
                overlap
                color="green"
              >
                <v-btn
                  class="white msaBlue--text"
                  @click="confirm()"
                  :disabled="selected.length == 0"
                >
                  import
                </v-btn>
              </v-badge>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import FormTemplateList from '@/components/FormTemplates/FormTemplateList.vue';
export default {
  name: 'Import1LifeFormTemplates',
  components: {
    FormTemplateList,
    SearchBar,
  },
  computed: {
    isSearching() {
      return this.search.trim().length > 0;
    },
  },
  data() {
    return {
      items: [],
      dialog: false,
      search: '',
      selected: [],
      expanded: [],
    };
  },
  methods: {
    onSearch(event) {
      this.search = event;
      this.get1LifeTemplates();
    },
    get1LifeTemplates() {
      const params = {
        loaderText: 'loading...',
        search: this.search,
      };
      const url = 'get-public-form-templates-for-company?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data;
          this.$nextTick(() => {
            this.expandAll();
          });
        })
        .catch((error) => error);
    },
    expandAll() {
      this.expanded = this.items.map((_, i) => i);
    },
    close() {
      this.selected = [];
      this.search = '';
      this.$refs.searchBar.setSearch(this.search);
      this.items = [];
      this.expanded = [];
      this.dialog = false;
    },
    handleSelection(event) {
      if (event.value) {
        this.selected = this.selected
          .concat(event.formTemplates)
          // remove duplicates
          .filter(
            (item, index, self) =>
              self.findIndex((t) => t.id == item.id) == index,
          );
      } else {
        this.selected = this.selected.filter(
          (selected) =>
            !event.formTemplates.some(
              (formTemplate) => formTemplate.id == selected.id,
            ),
        );
      }
    },
    confirm() {
      const params = {
        loaderText: 'loading...',
        publicFormTemplateIds: this.selected.map((s) => s.id),
      };
      const url = 'import-public-form-templates?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('imported');
          this.close();
        })
        .catch((error) => error);
    },
  },
};
</script>

<style></style>
